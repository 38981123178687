/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import useAuth from 'commons/hooks/auth';
import { StrapiPageInterface } from 'commons/types/strapi.type';
import IPage, { IPageType } from 'components/IPage';
import { APP_ISR_REVALIDATE_SECONDS } from 'constants/app';
import { HOME, LOGIN } from 'constants/routes';
import { GetStaticPaths } from 'next';
import { getIPageByAuth, getIPageByRouteUrl, getIPages } from 'utils/ipage';
import { fetchAPI, getPageData } from 'utils/strapi';
import { StrapiPageContextInterface } from 'components/Strapi';
import {
  STRAPI_COMMUNITY_PAGE,
  STRAPI_EVENT_SIGNUP_PAGE,
  STRAPI_FAQ_PAGE,
  STRAPI_INFO_PAGE,
  STRAPI_JOBS_PAGE,
  STRAPI_MOBILE_COMMUNITY_PAGE,
  STRAPI_MOBILE_PRIVACY_PAGE,
  STRAPI_MOBILE_TERMS_PAGE,
  STRAPI_PRIVACY_PAGE,
  STRAPI_TERMS_PAGE,
} from 'constants/strapi-urls';
import Loader from 'components/Loader';
import StaticHomeIPage from 'components/IPage/StaticHomeIPage';

const HomePage = dynamic(() => import('./Feed'), { ssr: false });
// import HomePage from './Feed';
// import Loader from 'components/Loader';
// const Loader = dynamic(() => import('components/Loader'), { ssr: false });
const StrapiPage = dynamic(() => import('components/Strapi'), { ssr: false });

const staticHomePageIpage = {
  createdAt: '2023-08-10T00:00:00.000Z',
  createdBy: 13,
  id: 16,
  isDeleted: false,
  isLive: true,
  previewLink: 'mainpage',
  requiresLogin: false,
  routeUrl: '/',
  showOnPhone: true,
  sourceUrl: 'https://mkt.husslup.com/NLIHP',
  title: 'HUSSLUP',
  updatedAt: '2023-10-06T21:55:23.890Z',
};

const DynamicPage: FunctionComponent<{
  pageContext: StrapiPageContextInterface;
  preview: boolean;
  notFound: boolean;
  ipageData: any;
}> = ({ notFound, pageContext, preview, ipageData }) => {
  const router = useRouter();
  const { isFallback, asPath: pathname, query } = router;
  const [loading, setLoading] = useState<boolean>(true);

  const { isLoggedIn, isAuthorizationReady } = useAuth();
  const [iPageData, setIPageData] = useState<IPageType | undefined>(undefined);

  useEffect(() => {
    // const fetchIPages = async () => {
    // const pages = await getIPages();
    if (ipageData) {
      localStorage.setItem('ipages', JSON.stringify(ipageData));
    }
    // };
    // fetchIPages();
  }, [ipageData]);

  useEffect(() => {
    // for private pages
    const fetchIPageUsingAuth = async (
      routeUrl: string,
      preview?: string | string[] | undefined,
    ) => {
      const data = await getIPageByAuth(routeUrl, preview);
      if (!data && !isLoggedIn) {
        router.push(
          {
            pathname: LOGIN,
            query: { from: router.asPath },
          },
          LOGIN,
        );
      }
      setIPageData(data);
      setLoading(false);
    };

    // for first time page load
    const fetchIPage = async (
      routeUrl: string,
      preview?: string | string[] | undefined,
    ) => {
      const data = await getIPageByRouteUrl(routeUrl, preview);
      if (data) setIPageData(data);
      else setIPageData(undefined);
      setLoading(false);
    };
    if (notFound !== undefined) {
      setLoading(true);
      const ipagesFromstorage = localStorage.getItem('ipages');
      const { slug, preview } = query;
      const previewLink = preview ?? undefined;
      let routeUrl = '/';
      if (slug && Array.isArray(slug)) routeUrl = slug.join('/');
      if (ipagesFromstorage) {
        const ipages = JSON.parse(ipagesFromstorage);
        const data: IPageType = ipages.find(
          (ipage: IPageType) => ipage.routeUrl == routeUrl,
        );
        if (!data) {
          setIPageData(undefined);
          setLoading(false);
        } else if (!data.requiresLogin) {
          if (data.isLive) setIPageData(data);
          else if (!data.isLive && !previewLink) setIPageData(undefined);
          else if (!data.isLive && previewLink == data.previewLink)
            setIPageData(data);
          else setIPageData(undefined);

          setLoading(false);
        } else {
          if (data.isLive) {
            fetchIPageUsingAuth(routeUrl);
          } else if (!data.isLive && !previewLink) {
            setIPageData(undefined);
            setLoading(false);
          } else if (!data.isLive && previewLink) {
            fetchIPageUsingAuth(routeUrl, previewLink);
          } else {
            router.push(
              {
                pathname: LOGIN,
                query: { from: router.asPath },
              },
              LOGIN,
            );
            setLoading(false);
          }
        }
      } else fetchIPage(routeUrl, preview);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1000);
    }
  }, [query, notFound]);

  // If page is creating or router is not ready yet then show loading
  // if (isFallback || Boolean(isLoggedIn && !isAuthorizationReady)) {
  //   return <Loader />;
  // }

  // console.log(
  //   isFallback,
  //   // isLoggedIn,
  //   isAuthorizationReady,
  //   'getPostWithTagParams',
  // );

  const isHomepage = useMemo(
    () => isLoggedIn && pathname === HOME,
    [isLoggedIn, pathname],
  );

  return (
    <StaticHomeIPage
      pageContext={staticHomePageIpage}
      loading={false}
      notFound={notFound}
    />
  );

  if (loading) {
    return <Loader />;
  }

  return isLoggedIn && pathname === HOME ? (
    <HomePage />
  ) : pathname === STRAPI_INFO_PAGE ||
    pathname === STRAPI_FAQ_PAGE ||
    pathname === STRAPI_TERMS_PAGE ||
    pathname === STRAPI_COMMUNITY_PAGE ||
    pathname === STRAPI_EVENT_SIGNUP_PAGE ||
    pathname === STRAPI_JOBS_PAGE ||
    pathname === STRAPI_MOBILE_PRIVACY_PAGE ||
    pathname === STRAPI_MOBILE_COMMUNITY_PAGE ||
    pathname === STRAPI_MOBILE_TERMS_PAGE ||
    pathname === STRAPI_PRIVACY_PAGE ? (
    // remove this after all ipages are implemented
    <StrapiPage preview={preview} context={pageContext} />
  ) : notFound !== undefined ? (
    <IPage pageContext={iPageData} loading={loading} notFound={notFound} />
  ) : (
    <></>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  try {
    const pages = await fetchAPI('/pages', {
      fields: ['slug'],
    });

    const paths =
      pages?.data?.map((page: StrapiPageInterface) => {
        const { slug } = page.attributes;
        const slugArray = !slug ? [''] : slug.split('/');

        return {
          params: { slug: slugArray },
        };
      }) || [];

    return { paths, fallback: true };
  } catch (err) {
    return {
      paths: [],
      fallback: false,
    };
  }
};

export const getStaticProps = async (context: {
  params: { slug: string[] };
  preview?: null | undefined;
}) => {
  const { params, preview = null } = context;
  const currentSlug = !params.slug ? ['index'] : params.slug;

  try {
    const pageData = await getPageData({
      slug: currentSlug.join('/'),
      preview,
    });

    if (pageData == null) {
      throw new Error('Page data not found');
    }

    const {
      contentSections,
      metadata,
      slug,
      category,
      loginRequired = false,
    } = pageData.attributes;

    const pageContext = {
      slug,
      category,
      sections: contentSections,
      metadata,
      loginRequired,
    };

    return {
      props: {
        preview,
        pageContext,
        notFound: false,
      },
      revalidate: APP_ISR_REVALIDATE_SECONDS,
    };
  } catch (err) {
    try {
      const pages = await getIPages();

      return {
        props: {
          ipageData: pages,
          notFound: true,
          preview: false,
        },
      };
    } catch (errIpage) {
      return {
        props: {
          notFound: true,
          preview: false,
        },
      };
    }
  }
};

export default DynamicPage;
